////////////////////////////////////////////////////////////
//  Program: Inspection Form.jsx                          //
//  Application:  Inspection  Form                        //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-08                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Table, Modal } from "react-bootstrap";
import AuditService from "../../services/AuditService";
import Moment from "moment";
import { Link } from "react-router-dom";
import exportIcon from "./../../assets/images/export-icon.svg";
import blacktick from "./../../assets/images/black-tick.svg";
import redtick from "./../../assets/images/red-tick.svg";
import bluetick from "./../../assets/images/blue-tick.svg";
import brownArrow from "./../../assets/images/brown-arrow.svg";
import Alerts from "../common/Alerts";
import moment from 'moment-timezone';
import DeleteImageModal from '../DeleteImageModal';
import ActionItemService from "../../services/ActionItemService";

export class InspectionForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showBasicModal: false,
      slug: this.props.match.params.slug,
      submission: [],
      mro: [],
      answer: global.mroAnswer,
      imagePath:'https://d10ouaf4mx3to1.cloudfront.net/audit/',
      isSubmit: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      showModalImage: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.deleteAudit = this.deleteAudit.bind(this);
    this.opendeleteAuditModal = this.opendeleteAuditModal.bind(this);
    this.openNoImagePopup = this.openNoImagePopup.bind(this);
    this.onCloseImagePop = this.onCloseImagePop.bind(this);
  }
  async insPdfGenerator(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let res = await AuditService.getAuditPdfBySlug(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data.report["0"] ? res.data.report["0"] : [];
    }
    this.setState({ list: list });
    this.setState({ loading: false });
    window.open(this.state.list.filePath, "_blank");
  }
  closeModal() {
    this.setState({ showBasicModal: false,showAlertModal: false });
  }

  opendeleteAuditModal(slug) {
    this.setState({ deleteAuditModal: true });
  }
  
  openNoImagePopup() {
    this.setState({ noImageModel: true });
  }

  closeopenNoImagePopup() {
    this.setState({ noImageModel: false });
  }

  actionItemRedirect() {
    window.open(`/action-item/update/${this.state.action_slug}` );
  }
  openAuditUpdate() {
    window.open(`/audit-resume/${this.state.slug}`, "_self" );
  }
  async componentDidMount() {
    this.getAudit();
  }

  async getAudit() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await AuditService.getAuditRowBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data.data ? res.data.data : {};
        resData.audit_no = resData.audit_no ? resData.audit_no : "";
        resData.audit_name = resData.audit_template
          ? resData.audit_template.audit_name
          : "";
        resData.audit_percentage =(resData.audit_percentage !== undefined && resData.audit_percentage !== null && resData.audit_percentage !== "")
        ? resData.audit_percentage + '%'
        : "";
        resData.property_name = resData.property
          ? resData.property.property_name
          : "";
        resData.auditor = resData.auditor ? resData.auditor.full_name : "";

        resData.category = resData.category ? resData.category : "";
        resData.mro = resData.mro ? resData.mro : "";
        resData.status_id = resData.status_id ? resData.status_id : "";
        resData.audit_status = resData.audit_status ? resData.audit_status : "";
        resData.timezone = resData?.property ? resData?.property?.timezone?.timezone_name : "";
        var statusName = "";
        if (resData.audit_status === 1) {
          statusName = "In Progress";
        }else if (resData.audit_status === 2) {
          statusName = "Follow-up";
        }else if (resData.audit_status === 3) {
          statusName = "Completed";
        } else {
          statusName = "Wrong status";
        }
        this.setState(resData);
        this.setState({
          time_out: Moment(resData.completed_on).format("DD MMM, YY - hh:mm A"),
          statusName:statusName
        });
        this.setState({
          time_in: moment(moment.utc(resData.started_on).toDate()).local().format("DD MMM, YY - hh:mm A"),
          // time_in: Moment(resData.started_on).format("DD MMM, YY - hh:mm A"),
        });
      }
      this.setState({ loading: false });
      var isAvailConst = 0;
      if (this.state.category) {
        this.state.category.map((cat, j) => {
          if (cat.tasks) {
            cat.tasks.map((tsk, i) => {
              if (tsk.is_action_with_note == 1) {
                isAvailConst = 1;
              }
            });
          }
        });
      }
      this.setState({ isAvail: isAvailConst });
    }
  }

// delete audit
closedeleteAuditModal() {
  this.setState({ deleteAuditModal: false });
}

  async deleteAudit(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await AuditService.deleteAudit(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteAuditModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/audits"), global.redirect_time);
    } else {
      this.closedeleteAuditModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  // Delete MRO Image Start
  async deleteImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await ActionItemService.deleteImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState(prevState => ({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
        classRemove: false,
        mroImages: prevState.mroImages.filter(image => image.slug !== slug)  // Remove the deleted image
      }));
      this.getAudit()
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete MRO Image End
  toggleModal(slug) {
    this.setState({
      showModalImage: true,
      imageSlug: slug,
      classRemove: true,
    });
  }
  onCloseImagePop() {
    this.setState({ showModalImage: false, classRemove: false});
  }
  imageRedirect(path) {
    window.open(`${path}` );
  }
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  render() {
    const additionalClass = this.state.classRemove ? 'd-none' : '';
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle ">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Audit</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        {global.userPermissions.checkPermission(
                          "audits-resume"
                        ) &&
                          (this.state.audit_status === 1 ? (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  this.openAuditUpdate(this.state.slug);
                                }}
                                className="gf-primary-btn-small-one  loader_btn"
                              >
                                Resume
                              </Button>
                            </>
                          ) : this.state.audit_status === 2 ? (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  this.openAuditUpdate(this.state.slug);
                                }}
                                className="gf-primary-btn-small-one  loader_btn"
                              >
                                Re-audit
                              </Button>
                            </>
                          ) : (
                            ""
                          ))}
                        {global.userPermissions.checkPermission(
                          "audits-delete"
                        ) && (
                          <Button
                            type="button"
                            onClick={() => {
                              this.opendeleteAuditModal(this.state.slug);
                            }}
                            className="gf-primary-btn-small-one  mg-l-7  loader_btn"
                          >
                            Delete Audit
                          </Button>
                        )}
                        {global.userPermissions.checkPermission(
                          "audits-list"
                        ) && (
                          <Button
                            onClick={this.insPdfGenerator}
                            className="gf-primary-btn-small-one mg-l-7 loader_btn align-items-center export_pdf_btn"
                          >
                            <img src={exportIcon} alt="" />
                            {this.state.loading ? global.loader : "Export PDF"}
                          </Button>
                        )}
                      </div>
                    </div>
                    <span>Audit Detail</span>
                  </h3>
                </div>
              </div>
            </div>
            {/* for inspection info */}
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10">
                <div className="brandList dashboardRightcard d-flex flex-wrap justify-content-between align-items-center">
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">Audit No.</label>
                    <p>#{this.state.audit_no}</p>
                  </div>
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">
                      Audit Template
                    </label>
                    <p>{this.state.audit_name}</p>
                  </div>
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">
                      Property Name
                    </label>
                    <p>{this.state.property_name}</p>
                  </div>

                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">Conducted On</label>
                    <p>{this.state.time_in}</p>
                  </div>

                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">Created By</label>
                    <p className="m-0">{this.state.auditor}</p>
                  </div>
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">
                      Overall Score{" "}
                    </label>
                    <p className="m-0">
                      {this.state.audit_status === 3
                        ? this.state.audit_percentage
                        : ""}{" "}
                    </p>
                  </div>

                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">
                      Current Status
                    </label>
                    <p className="m-0">{this.state.statusName}</p>
                  </div>
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left">
                      Timezone
                    </label>
                    <p className="m-0">{this.state.timezone}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* for task */}

            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-10 pd-b-10">
                <div className="brandSection">
                  <div className="mg-t-0 brandList dashboardRightcard pt-2 pb-2  scroll_mro">
                    <div className="table-responsive tableFormatesimple">
                      <Table bordered className="mg-b-0">
                        <thead>
                          <tr>
                            <th>TASK</th>
                            <th>YES</th>
                            <th>NO</th>
                            <th>NA</th>
                            <th className="az-inspection-blue">NOTES</th>
                            <th className="az-inspection-red">ACTION ITEMS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.category
                            ? this.state.category.map((element, index) => (
                                <>
                                  <tr>
                                    <th
                                      colSpan="5"
                                      className="p-0 border-bottom-0"
                                    >
                                      <caption>
                                        {element.category_name
                                          ? element.category_name
                                          : ""}
                                      </caption>
                                    </th>
                                    <th className="p-0 border-bottom-0">
                                      <caption>
                                        {element.audit_cate_percentage !==
                                          undefined &&
                                        element.audit_cate_percentage !==
                                          null &&
                                        element.audit_cate_percentage !== ""
                                          ? element.audit_cate_percentage +
                                            "%" +
                                            "(" +
                                            element.audit_cate_task_pass +
                                            "/" +
                                            element.audit_cate_task_total +
                                            ")"
                                          : "0%(0/0)"}
                                      </caption>
                                    </th>
                                  </tr>
                                  {element.tasks
                                    ? element.tasks.map((el, indexes) => (
                                        <>
                                          <tr>
                                            <th
                                              scope="row"
                                              className="az-inspection-black insp_inner_p"
                                            >
                                              {" "}
                                              {el.task_name ? (
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: this.modifyTaskName(
                                                      el.task_name
                                                    ),
                                                  }}
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                            </th>
                                            <td>
                                              {" "}
                                              {el.task_status == 1 ? (
                                                <label>
                                                  <img src={blacktick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.task_status == 2 ? (
                                                <label>
                                                  <img src={blacktick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.task_status == 3 ? (
                                                <label>
                                                  <img src={blacktick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.is_action_with_note == 2 ? (
                                                <label
                                                  className="log hand-cursor text-primary"
                                                  onClick={() =>
                                                    this.setState({
                                                      showBasicModal: true,
                                                      title: el.action.title,
                                                      note: el.action.note,
                                                      is_action:
                                                        el.is_action_with_note,
                                                      action_slug:
                                                        el.action.slug,
                                                      mroImages:
                                                        el.action.images,
                                                    })
                                                  }
                                                >
                                                  <img src={bluetick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              <code>
                                                {el.is_action_with_note == 1 ? (
                                                  <label
                                                    className="log hand-cursor text-primary"
                                                    onClick={() =>
                                                      this.setState({
                                                        showBasicModal: true,
                                                        title: el.action.title,
                                                        note: el.action.note,
                                                        is_action:
                                                          el.is_action_with_note,
                                                        action_slug:
                                                          el.action.slug,
                                                        mroImages:
                                                          el.action.images,
                                                      })
                                                    }
                                                  >
                                                    <img src={redtick} alt="" />
                                                  </label>
                                                ) : (
                                                  ""
                                                )}
                                              </code>
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    : ""}
                                </>
                              ))
                            : ""}
                        </tbody>
                      </Table>
                    </div>
                    {/* table-responsive */}
                  </div>
                </div>
              </div>
            </div>
            {/* for mro */}
            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-10 pd-b-10">
                <div className="brandSection">
                  <div className="mg-t-0 brandList dashboardRightcard pt-2 pb-2 scroll_mro">
                    <div className="table-responsive tableFormatesimple">
                      <Table striped className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="border-right-0 wid_50">
                              ACTION ITEMS
                            </th>
                            <th className="border-right-0 wid_25">STATUS</th>
                            <th className="border-left-0">VIEW DETAILS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.category
                            ? this.state.category.map((elm, j) =>
                                elm.tasks
                                  ? elm.tasks.map((el, i) =>
                                      el.is_action_with_note == 1 ? (
                                        <tr key={i}>
                                          <th
                                            scope="row"
                                            className="az-inspection-black border-right-0 insp_inner_p"
                                          >
                                            {el.action ? (
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: this.modifyTaskName(
                                                    el.action.title
                                                  ),
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <Link
                                              to={
                                                "/action-item/update/" +
                                                (el.action
                                                  ? el.action.slug
                                                  : "")
                                              }
                                              className="az-inspection-brown"
                                            >
                                              <code>
                                                {" "}
                                                (
                                                {el.action
                                                  ? el.action.action_number
                                                  : ""}
                                              </code>{" "}
                                              )
                                            </Link>
                                          </th>

                                          <th className="az-inspection-black border-right-0 pl-0">
                                            <Link
                                              to={
                                                "/action-item/update/" +
                                                (el.action
                                                  ? el.action.slug
                                                  : "")
                                              }
                                              className="az-inspection-black border-right-0 pl-3"
                                            >
                                              {" "}
                                              {el.action
                                                ? el.action.status_id == 1
                                                  ? "Pending"
                                                  : "Completed"
                                                : ""}{" "}
                                            </Link>
                                          </th>
                                          <td className="border-left-0 pl-5">
                                            <Link
                                              to={
                                                "/action-item/update/" +
                                                (el.action
                                                  ? el.action.slug
                                                  : "")
                                              }
                                              className=""
                                            >
                                              <img src={brownArrow} alt="" />
                                            </Link>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )
                                    )
                                  : ""
                              )
                            : ""}
                          {this.state.isAvail === 0 ? (
                            <tr>
                              <th colSpan={3} className="p-0 border-bottom-0">
                                <caption>No Record Found</caption>
                              </th>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {/* bd */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {this.state.showModalImage && (
          <DeleteImageModal
            showModalImage={this.state.showModalImage}
            slug={this.state.imageSlug}
            onRemove={() => this.deleteImage(this.state.imageSlug)}
            onClose={this.onCloseImagePop}
          />
        )}
        <Modal show={this.state.showBasicModal} centered>
          <Modal.Body
            className={`width_402 p-4 border_radius_14 ${additionalClass}`}
          >
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">
              {this.state.is_action && this.state.is_action == 1
                ? "Action Item"
                : "Notes"}{" "}
            </h3>
            <p className="text-center text-center model_first_p">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.modifyTaskName(this.state.title),
                }}
              />
            </p>
            <p className="text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.modifyTaskName(this.state.note),
                }}
              />
            </p>

            <div className="d-flex cnt_center row mt-3">
              {this.state.mroImages
                ? this.state.mroImages.map((record, i) => {
                    if (record.uri) {
                      return (
                        <div key={"Data" + i} className="position-relative">
                          <img
                            key={"Data" + i}
                            src={record.uri}
                            className="note_popup_img hand-cursor"
                            alt=""
                            onClick={() => this.imageRedirect(record.uri)}
                          />
                          {global.userPermissions.checkPermission(
                            "action_items-delete"
                          ) && (
                            <>
                              <button
                                type="button"
                                className="btN btn_remove position-absolute"
                                // style={{ top: '10px', right: '10px' }}
                                onClick={() => this.toggleModal(record.slug)}
                              >
                                <span className="fa fa-times"></span>
                              </button>
                            </>
                          )}
                          {this.state.is_action &&
                          this.state.is_action == 2 &&
                          this.state.is_action != 1 ? (
                            <button
                              type="button"
                              className="btN btn_remove position-absolute"
                              // style={{ top: '10px', right: '10px' }}
                              onClick={() => this.toggleModal(record.slug)}
                            >
                              <span className="fa fa-times"></span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <img
                          key={"Data" + i}
                          className="note_popup_img hand-cursor"
                          alt=""
                          onClick={() => this.openNoImagePopup(record.uri)}
                        />
                      );
                    }
                  })
                : ""}
            </div>
            <div className="d-flex cnt_center row mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal()}
              >
                Close
              </button>
              {this.state.is_action && this.state.is_action == 1 ? (
                <>
                  <button
                    type="button"
                    className="m-0 btn btn-outline-light btn-block btn-thene-modal"
                    onClick={() => this.actionItemRedirect()}
                  >
                    Detail
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.deleteAuditModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Audit?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this Audit? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteAuditModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAudit(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.noImageModel} centered>
          <Modal.Body className="width_402 p-4 border_radius_14 no_img_pop">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>No image</h3> */}
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                No Image Found.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeopenNoImagePopup()}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default InspectionForm;
