import ReactDatatable from "@ashvin27/react-datatable";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import MroService from "../../services/MroService";
import PropertyService from "../../services/PropertyService";
import exportIcon from "./../../assets/images/export-icon.svg";
import UserService from "../../services/UserService";
import Moment from "moment";
// import customerLogo from "./../../assets/images/default.png";
import back from "./../../assets/images/back.svg";
export class MroTracker extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    let firstDayOfMonth,lastDayOfMonth;
    if(global.roleTypeId === 4) {
      firstDayOfMonth = Moment().startOf('year').toDate();
      lastDayOfMonth = Moment().endOf('year').toDate();
    } else {
      firstDayOfMonth = Moment().subtract(6, 'months').toDate();
      lastDayOfMonth = new Date();
    }
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      start_date: firstDayOfMonth,
      end_date: lastDayOfMonth,
      currentYear: new Date().getFullYear(),
      property_slug: "",
      selectedPropertyList: [],
      propertySlug: "",
      propertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      userList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      records: [],
      trackerTypeText: "",
      trackerType: "",
      propertyLable: "",
      isFilterTrue: false,
      config: {
        sort: {
          column: "mro_number",
          order: "desc",
        },
        key_column: "slug",
        page_size: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: false,
        show_info: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "mro_number",
          className: "pl-2 hand-cursor",
          text: "MRO",
          sortable: true,
          cell: (record) => {
            return record.mro_number ? "#" + record.mro_number : "";
          },
        },
        {
          key: "title",
          className: "pl-10 hand-cursor",
          text: "Title",
          sortable: true,
          cell: (record) => {
              const note = record.title ? this.limitTextTo50Words(record.title) : "";
              return note;
          },
        },
        {
          key: "location_name",
          className: "pl-2 hand-cursor",
          text: "Location",
          sortable: true,
          cell: (record) => {
            return record.location_name
              ? record.location_name
              : "";
          },
        },
        {
          key: "created_at",
          className: "pl-2 hand-cursor",
          text: "Request Date",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format("DD MMM, YY - hh:mm A")
              : "";
          },
        },
        {
          key: "full_name",
          className: "pl-2 hand-cursor",
          text: "Assigned to",
          sortable: true,
          cell: (record) => {
            return record.full_name
              ? record.full_name
              : "";
          },
        },
        
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetData = this.resetData.bind(this);
  }

  async insPdfGenerator(queryString = "") {
    this.setState({ loadingexport: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterMroTrackerData = JSON.parse(localStorage.getItem("mroTrackerLocation"));
    let filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
    const propertySlugG = (filterData && filterData.property_name !== "All Properties")
    ? filterData?.property_slug 
    : (filterPropertyData?.property_slug 
        ? filterPropertyData.property_slug 
        : (this.state.propertyList.length > 0 
            ? this.state.propertyList[0].value 
            : ""));
    if (filterData) {
      queryString =
        "start_date=" +
        Moment(filterData.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        (filterData.end_date
          ? Moment(filterData.end_date).format("YYYY-MM-DD")
          : "") +
        "&property_slug=" +
        propertySlugG +
        "&zone_type_id=" +
        (filterMroTrackerData && filterMroTrackerData.zone_type_id ? filterMroTrackerData.zone_type_id : "") +
        "&location_slug=" +
        (filterMroTrackerData && filterMroTrackerData.location_slug ? filterMroTrackerData.location_slug : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&user_slug=" +
        ( filterData.user_slug ? filterData.user_slug : "") +
        "&is_pdf=" +
        1;
      } else {
        if (this.state.propertyList.length > 0) {
          var firstProperty = filterPropertyData 
          ? { 
              label: filterPropertyData.property_name, 
              value: filterPropertyData.property_slug 
            }
          : this.state.propertyList[0];
          }
        queryString =
         "start_date=" +
          Moment(this.state.start_date).format("YYYY-MM-DD") +
          "&end_date=" +
          (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
          "&property_slug=" + firstProperty.value +
          "&sort_column=" +
          (this.state.sort_column ? this.state.sort_column : "") +
          "&sort_order=" +
          (this.state.sort_order ? this.state.sort_order : "") +
          "&is_pdf=" +
          1;
      }
    let res = await MroService.getMroTracker(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.report["0"] ? res.data.report["0"] : [];
    }
    this.setState({
      loadingexport: false,
      list: list,
    });
    window.open(this.state.list.filePath, "_blank");
  }

  componentDidMount() {
    this.globalDataSet();
    this.getPropertyList();
    this.setState({ zoneTypeList: global.zoneTypeList });
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterMroTrackerData = JSON.parse(localStorage.getItem("mroTrackerLocation"));
    if (
      filterData &&
      filterData.property_slug != undefined &&
      filterData.property_slug != ""
    ) {
      this.getLocation("property_slug=" + filterData.property_slug);
      this.getUserList("property_slug=" + filterData.property_slug);
      if (filterMroTrackerData && 
        filterMroTrackerData.location_slug != undefined &&
        filterMroTrackerData.location_slug != "" &&
        filterMroTrackerData.zone_name != undefined &&
        filterMroTrackerData.zone_name != ""
      ) {
        this.setState({
          selecteLocationList: {
            label: filterMroTrackerData.zone_name,
            value: filterMroTrackerData.location_slug,
            zone_type_id: filterMroTrackerData.zone_type_id,
          },
        });
      }
    } else {
      this.getUserList();
    }
  }
  globalDataSet = () => {
    var singleUser =
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
        ? parseInt(localStorage.getItem("activePropertyCount"))
        : "";
    if (singleUser === 1) {
      const outSidePropertySlug = localStorage.getItem('outSidePorpertySlug') || '';
      const singlePropertySlug = localStorage.getItem('singlePorpertySlug') || '';
      const selectedPropertyName = localStorage.getItem('selectedPropertyName') || '';
      const singlePropertyName = localStorage.getItem('singlePropertyName') || 'All Properties';
      var singlePropertyName1 = outSidePropertySlug !== '' ? selectedPropertyName : singlePropertyName;
      var singlePropertySlug1 = outSidePropertySlug !== '' ? outSidePropertySlug : singlePropertySlug;
      let filterData = JSON.parse(localStorage.getItem("globleTracker"));
      let selectedStartDate, selectedEndDate;
      if(global.roleTypeId === 4) {
        selectedStartDate = filterData ? Moment(filterData.start_date).format("YYYY-MM-DD") : Moment().startOf('year').toDate();
        selectedEndDate = filterData ? Moment(filterData.end_date).format("YYYY-MM-DD") : Moment().endOf('year').toDate();
      } else {
        selectedStartDate = Moment().subtract(6, 'months').toDate(); // 6 months before today
        selectedEndDate = new Date();
      }
      var filterValue =
        '{"start_date" :"' +
        selectedStartDate +
        '", "end_date" :"' +
        selectedEndDate +
        '", "property_slug":"' +
        singlePropertySlug1 +
        '", "property_name":"' +
        singlePropertyName1 +
        '"}';
      localStorage.setItem("globleTracker", filterValue);
    }
  };
  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterMroTrackerData = JSON.parse(localStorage.getItem("mroTrackerLocation"));
    let filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
    const propertySlugG = (filterData && filterData.property_name !== "All Properties")
    ? filterData?.property_slug 
    : (filterPropertyData?.property_slug 
        ? filterPropertyData.property_slug 
        : (this.state.propertyList.length > 0 
            ? this.state.propertyList[0].value 
            : ""));
    if (filterData) {
      const startDate = filterData.start_date 
        ? Moment(filterData.start_date).toDate() 
        : Moment().subtract(6, 'months').toDate();
      
      const endDate = filterData.end_date 
        ? Moment(filterData.end_date).toDate() 
        : new Date();
      queryString =
        "start_date=" +
        Moment(startDate).format("YYYY-MM-DD") +
        "&end_date=" +
        Moment(endDate).format("YYYY-MM-DD") +
        "&property_slug=" +
        propertySlugG +
        "&zone_type_id=" +
        (filterMroTrackerData && filterMroTrackerData.zone_type_id ? filterMroTrackerData.zone_type_id : "") +
        "&location_slug=" +
        (filterMroTrackerData && filterMroTrackerData.location_slug ? filterMroTrackerData.location_slug : "") +
        "&zone_name=" +
        (filterMroTrackerData && filterMroTrackerData.zone_name ? filterMroTrackerData.zone_name : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&user_slug=" +
        ( filterData.user_slug ? filterData.user_slug : "") ;
        let selectedPropertyList;
        if(filterData && filterData.property_name !== "All Properties") {
          selectedPropertyList = {
            label: filterData.property_name,
            value: filterData.property_slug,
          };
        } else {
          selectedPropertyList = {
            label: filterPropertyData ? filterPropertyData.property_name : this.state.propertyList[0].label,
            value: filterPropertyData ? filterPropertyData.property_slug : this.state.propertyList[0].value,
          };
          
        }
        var selecteAssigneeList = filterData.selecteAssigneeList;
        this.setState({
          propertySlug:  selectedPropertyList.value,
          propertyLable: selectedPropertyList.label,
          start_date: startDate,
          end_date: endDate,
          selectedPropertyList: selectedPropertyList,
          selecteAssigneeList : selecteAssigneeList,
          zone_name: filterMroTrackerData ? filterMroTrackerData.zone_name : "",
          location_slug: filterMroTrackerData ? filterMroTrackerData.location_slug : "",
          zone_type_id: filterMroTrackerData ? filterMroTrackerData.zone_type_id : "",
          user_slug: filterData.user_slug ? filterData.user_slug : "",
        });
    } else {
      if (this.state.propertyList.length > 0) {
        const firstProperty = (filterData && filterData.property_name !== "All Properties") 
        ? { 
            label: filterPropertyData?.property_name, 
            value: filterPropertyData?.property_slug 
          } 
        : (filterPropertyData 
            ? { 
                label: filterPropertyData.property_name, 
                value: filterPropertyData.property_slug 
              }
        : this.state.propertyList[0]);
        this.setState({
            selectedPropertyList: firstProperty,
            propertySlug: firstProperty.value,
            propertyLable: firstProperty.label,
        });
      queryString =
       "start_date=" +
        Moment(this.state.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
        "&property_slug=" + firstProperty.value +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "");
        this.getLocation("property_slug=" + firstProperty.value);
      } else {
          queryString =
              "start_date=" +
              Moment(this.state.start_date).format("YYYY-MM-DD") +
              "&end_date=" +
              (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
              "&sort_column=" +
              (this.state.sort_column ? this.state.sort_column : "") +
              "&sort_order=" +
              (this.state.sort_order ? this.state.sort_order : "");
      }
    }
    this.setState({ records: [] });
    let res = await MroService.getMroTracker(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data && res.data.data ? res.data.data : [];
    }
    if (this.state.checkFilter === true) {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
    });
    if (list.length > 0) {
      this.setState({
        completed_on: Moment(list[0].completed_on).format("DD MMM, YY"),
      });
    }
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocationsMobile(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList =
        res && res.data.data ? res.data.data : res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
        zone_type_id: item.zone_type_id,
      });
    });
    this.setState({ locationList: locationListData });
  }

  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      // if (item.role_id >= global.roleTypeId) {
        userListData.push({
          label: item.full_name,
          value: item.slug,
        });
      // }
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1&is_tracker=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    if (propertyListData.length > 0) {
      this.setState({
        propertyList: propertyListData,
        selectedPropertyList: propertyListData[0], // Set the first property as selected by default
        property_slug: propertyListData[0].value,
        propertySlug: propertyListData[0].value,
        propertyLable: propertyListData[0].label,
      }, () => {
        let filterData = JSON.parse(localStorage.getItem("globleTracker"));
        let filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
        const propertySlug = (filterData && filterData.property_name !== "All Properties")
        ? filterData?.property_slug 
        : (filterPropertyData?.property_slug 
            ? filterPropertyData.property_slug 
            : (this.state.propertyList.length > 0 
                ? this.state.propertyList[0].value 
                : ""));
            
        if (propertySlug) {
            this.getLocation("property_slug=" + propertySlug);
        }
        
      });
    } else {
      this.setState({ propertyList: propertyListData });
    }
    this.getData();
  }

  customFilter() {
    const selectedStartDate = Moment(this.state.start_date).format(
      "YYYY-MM-DD"
    );
    let selectedEndDate = "";
    if (this.state.end_date && !isNaN(new Date(this.state.end_date))) {
      selectedEndDate = Moment(this.state.end_date).format("YYYY-MM-DD");
    }
    let queryString =
      "start_date=" +
      selectedStartDate +
      "end_date=" +
      selectedEndDate +
      "&property_slug=" +
      this.state.propertySlug +
      "&zone_type_id=" +
      (this.state.zone_type_id ? this.state.zone_type_id : "") +
      "&location_slug=" +
      (this.state.location_slug ? this.state.location_slug : "") +
      "&user_slug=" +
      (this.state.user_slug ? this.state.user_slug : "");
    this.setState({ tableQueryString: "", customQueryString: queryString });
    var filterValue =
      '{"start_date": "' +
      selectedStartDate +
      '", "end_date": "' +
      selectedEndDate +
      '", "property_slug" :"' +
      this.state.propertySlug +
      '", "property_name" :"' +
      this.state.propertyLable +
      '"' +
      (this.state.selecteAssigneeList
          ? ', "selecteAssigneeList": ' + JSON.stringify(this.state.selecteAssigneeList)
          : '') +
      (this.state.user_slug ? ', "user_slug": "' + this.state.user_slug + '"' : "") +
      '}';
    if(this.state.location_slug)
    {
      var filterValueLocation =
        (this.state.zone_type_id
          ? '{"zone_type_id" :"' + this.state.zone_type_id
          : "") +
        (this.state.location_slug
          ? '", "location_slug" :"' + this.state.location_slug
          : "") +
        (this.state.zone_name ? '", "zone_name" :"' + this.state.zone_name : "") +
          '"}';
      localStorage.setItem("mroTrackerLocation", filterValueLocation);
    }
    localStorage.setItem("globleTracker", filterValue);
    localStorage.setItem("globleTrackerProperty", JSON.stringify({
      property_slug: this.state.propertySlug,
      property_name: this.state.propertyLable,
    }));
    this.setState({ globalTrackerState: filterValue });
    this.setState({ isFilterTrue: true, checkFilter : true });
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    const filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty")) || '';
    const outSidePropertySlug = localStorage.getItem('outSidePorpertySlug') || '';
    const singlePropertySlug = localStorage.getItem('singlePorpertySlug') || '';
    const selectedPropertyName = localStorage.getItem('selectedPropertyName') || '';
    const singlePropertyName = localStorage.getItem('singlePropertyName') || 'All Properties';
    var outsideSlugPN = outSidePropertySlug !== '' ? selectedPropertyName : singlePropertyName;
    var outsideSlugP = outSidePropertySlug !== '' ? outSidePropertySlug : singlePropertySlug;
    if(outsideSlugPN !== "All Properties") {
      localStorage.removeItem("globleTrackerProperty");
    }
    const selectedProperty = (outsideSlugPN !== "All Properties")
    ? {
        label: outsideSlugPN,
        value: outsideSlugP
      }
    : (filterPropertyData
        ? {
            label: filterPropertyData.property_name,
            value: filterPropertyData.property_slug
          }
        : (this.state.propertyList.length > 0
            ? {
                label: this.state.propertyList[0].label , 
                value: this.state.propertyList[0].value , 
              }
            : null));
    this.setState({
      locationList: [],
      selecteLocationList: [],
      selectedZoneTypeList: "",
      propertyList: "",
      selectedPropertyList: "",
      trackerTypeText: "",
      trackerType: "",
      records: [],
      property_slug: "",
      zone_type: "",
      location_slug: "",
      selectedPropertyName: "",
      singleScreenProperty: "",
      singleScreen: false,
      propertySlug: "",
      propertyLable: "",
      selectStartDate: "",
      start_date: Moment().subtract(6, 'months').toDate(),
      end_date: new Date(),
      selectEndDate: "",
      zone_type_id: "",
      zone_name: "",
      checkFilter : false,
      // showFilter: 0,
      selecteAssigneeList: '',
    });

    localStorage.removeItem("globleTracker");
    localStorage.removeItem("mroTrackerLocation");
    document.body.classList.remove("bdy_over");
    this.getUserList()
    var GlobalApply =
      localStorage.getItem("outSidePorpertySlug") != "" &&
      localStorage.getItem("outSidePorpertySlug") != undefined
        ? parseInt(localStorage.getItem("outSidePorpertySlug"))
        : "";
    if (GlobalApply != undefined && GlobalApply != "") {
      const selectedStartDate = Moment().subtract(6, 'months').toDate();
      const selectedEndDate = new Date();
      var filterValue =
        '{"start_date":"' +
        selectedStartDate +
        '", "end_date":"' +
        selectedEndDate +
        '", "property_slug":"' +
        (localStorage.getItem('outSidePorpertySlug') != '' ? localStorage.getItem('outSidePorpertySlug'): localStorage.getItem('singlePorpertySlug')) +
        '", "property_name":"' +
        (localStorage.getItem('outSidePorpertySlug') != '' ? localStorage.getItem('selectedPropertyName'): localStorage.getItem('singlePropertyName')) +
        '"}';
      localStorage.setItem("globleTracker", filterValue);
    }
    if (
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
    ) {
      this.globalDataSet();
    }
    let queryString =
    "start_date=" +
    this.state.start_date +
    "&end_date=" +
    this.state.end_date +
    (selectedProperty ? "&property_slug=" + selectedProperty.value : "");
    this.getData(queryString);
    this.getPropertyList().then(() => {
      if (selectedProperty) {
          this.setState({
              selectedPropertyList: selectedProperty,
              propertySlug: selectedProperty.value,
              propertyLable: selectedProperty.label
          });
      }
  });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Location Change Handler
  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      zone_type_id: value.zone_type_id,
      zone_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selecteAssigneeList: value,
      user_slug: value.value,
    });
  }
  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.getLocation("property_slug=" + value.value);
    this.getUserList("property_slug=" + value.value);
    this.setState({
      selecteLocationList: [],
      locationList: [],
      trackerTypeText: "",
      trackerType: "",
      selectedPropertyList: value,
      property_slug: value.value,
      propertyLable: value.label,
      propertySlug: value.value,
      singleScreen:
        localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== ""
          ? true
          : false,
      singleScreenProperty: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  limitTextTo50Words(htmlContent) {
    // Parse HTML content to extract text
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const textContent = doc.body.textContent || "";

    // Limit the text content to 50 characters
    const limit = 30;
    let result = textContent.slice(0, limit);

    // Append "..." if the original content is longer than 50 characters
    if (textContent.length > limit) {
        result += '...';
    }

    return result;
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }
    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState(
        { 
          sort_order: data.sort_order.order, // Correct the mapping (column is order, order is column)
          sort_column: data.sort_order.column 
        }, 
        () => {
          // This callback ensures that getTrackerData runs after the state is updated
          this.getData(queryString);
        }
      );
    }
  };
  rowClickedHandler = (event, data, rowIndex) => {
    window.open("mros/update/" + data.slug, "_blank");
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid p-0">
            <div className="row row-xs">
              <div className="col-md-12 p-0">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center tracker_btn_new">
                    <h3 className="invisible"> </h3>
                    <div className="btnNavigate d-flex justify-content-end ">
                      <Button
                        onClick={this.insPdfGenerator}
                        className="gf-primary-btn-small-one mg-l-7 loader_btn align-items-center export_pdf_btn"
                        disabled={this.state.records.length === 0 ? true : false}
                      >
                        <img src={exportIcon} alt="" />
                        {this.state.loadingexport ? global.loader : "Export PDF"}
                      </Button>
                      <button
                        onClick={this.filterToggle}
                        className="gf-primary-btn-small-one mg-l-7"
                      >
                        {this.state.showFilter === 1 ? " Filter" : " Filter"}
                      </button>
                    </div>
                  </div>
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back ">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <Row className="align-items-start justify-content-between m-0 p-0">
                          <label className="form-label text-left mg-b-5 mt-0">
                            Start Date
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="start_date"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.start_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "start_date",
                                    value: data,
                                  },
                                })
                              }
                              maxDate={this.state.end_date}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            End Date
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="end_date"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.end_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "end_date",
                                    value: data,
                                  },
                                })
                              }
                              minDate={this.state.start_date}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Property
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedPropertyList}
                              options={this.state.propertyList}
                              onChange={(value) =>
                                this.handleChangeProperty(value)
                              }
                              defaultValue={this.state.selectedPropertyList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Assigned To
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selecteAssigneeList}
                              options={this.state.userList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeUserList(value)
                              }
                              defaultValue={this.state.selecteAssigneeList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Location{" "}
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selecteLocationList}
                              options={this.state.locationList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeLocation(value)
                              }
                              defaultValue={this.state.selecteLocationList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>

                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-4 pr-0"
                          >
                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="mgb-neg-20 brandList dashboardRightcard ">
              <div className="row">
                <div className="col-md-12">
                {this.state.start_date !== undefined &&
                    this.state.start_date !== "" ? (
                    <>
                      <div className="checklist_table_heading">
                        <span className="lspan">Start Date: &nbsp;</span>
                        <span className="rspan">
                        {Moment(this.state.start_date).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                   {this.state.end_date !== undefined &&
                    this.state.end_date !== "" ? (
                    <>
                      <div className="checklist_table_heading">
                        <span className="lspan">End Date: &nbsp;</span>
                        <span className="rspan">
                        {Moment(this.state.end_date).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.propertyLable !== undefined &&
                  this.state.propertyLable !== "" ? (
                    <>
                      <div className="checklist_table_heading">
                        <span className="lspan">Search Applied On: &nbsp;</span>
                        <span className="rspan">
                          {this.state.propertyLable
                            ? this.state.propertyLable
                            : ""}
                          {this.state.selecteAssigneeList 
                          ? ", " + this.state.selecteAssigneeList.label 
                          : ""}
                          {this.state.zone_name
                            ? ", " + this.state.zone_name
                            : ""}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2 customerTable table-bordered table_mro"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default MroTracker;
