////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Dashboard.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2022-03-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import DashboardService from "../../services/DashboardService";
import Landing from "./Landing";
import NotificationService from "../../services/NotificationService"
import RecentDailyLog from "./RecentDailyLog";
import RecentAudits from "./RecentAudits";
import RecentInspections from "./RecentInspections";
import InspectionNumber from "./InspectionNumber";
import AuditsNumber from "./AuditsNumber";
import MroStatus from "./MroStatus";
import ActionItemStatus from "./ActionItemStatus";
import MonAuditInspection from "./MonAuditInspection";
// import NotificationModelBox from "./NotificationModelBox";
// import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Moment from "moment";
import Select from "react-select";
// import { Col } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused
        ? "gray"
        : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),

  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent", 
    borderColor: "#cdd4e0",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: '0',
    '&:hover': {
      borderColor: "#cdd4e0", // Change this to the desired border color on hover
    },
  }),

  input: (provided) => ({
    ...provided,
    margin: 0, // Remove default margin
    padding: 0, // Remove default padding
    lineHeight: 'normal', // Ensure normal line height
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "transparent", // Change this to the desired background color
  }),
  // indicatorSeparator: () => ({
  //   display: 'none' // Hide the indicator separator
  // }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#1b1b1b', // Change this to the desired color of the arrow
  }),

  singleValue: (provided) => ({
    ...provided,
    color: 'gray', // Change this to the desired color for the default value
  }),
};

export class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: global.userData ? global.userData : {},
      count: 0,
      favoritefood: "rice",
      auditlist: [],
      inspectionList: [],
      dailyLogList: [],
      activeUsers: [],
      lastAudit: [],
      lastInspection: [],
      insp_count: [],
      doughnutPieData: [],
      notificationListModel: [],
      notislist: [],
      year: new Date(),
      totalNotification:"",
      unreadNotification:"",
      doughnutPieOptions: {
        maintainAspectRatio: false,
        responsive: true,
        series: {
          pie: {
            show: true,
            radius: 1,
            innerRadius: 0.5,
            label: {
              show: true,
            },
          },
        },
        legend: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        onClick: function (evt, element) {
          if (!global.userPermissions?.checkPermission("mros-list")) {
            return; 
          } 
          var index = element[0]._index;
          if (index === 0) {
            var status ="pending" ; // 2
          } else if (index === 1) {
             status = "on-going"; //3
          }else if (index === 2) {
             status = "overdue"; //4
          }else if (index === 3){
            status ="completed";
          }else if (index === 4){
            status ="backlog";
          }else if(index === 5)
          {
            status ="cancelled";
          }
          window.open('mros/filter/' + status, "_self");
          }
      },
      doughnutPieOptionsForAction: {
        maintainAspectRatio: false,
        responsive: true,
        series: {
          pie: {
            show: true,
            radius: 1,
            innerRadius: 0.5,
            label: {
              show: true,
            },
          },
        },
        legend: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        onClick: function (evt, element) {
          if (!global.userPermissions?.checkPermission("action_items-list")) {
            return; 
          } 
          var index = element[0]._index;
          if (index === 0) {
            var status ="pending" ; // 1
          }else if (index === 1) {
             status = "overdue"; //3
          }else if (index === 2) {
            status = "completed"; //3
         }
          window.open('action-item/filter/' + status, "_self");
        }
      },
      inspectionData: [],
      auditData: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            display: true,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.4,
              ticks: {
                fontSize: 11,
              },
            },
          ],
        },
      },
      isLoaded: false,
      isFirstLoad: true,
      delay: 1 * 15 * 1000,
    };
    this.notificationRead = this.notificationRead.bind(this);
    // this.notificationReadAll = this.notificationReadAll.bind(this);
    // this.notificationClearAll = this.notificationClearAll.bind(this);
    this.notificationModel = this.notificationModel.bind(this);
    this.getDashboardData = this.getDashboardData.bind(this);
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);

  }
  componentDidMount() {
    let startDate, endDate;
    if(global.roleTypeId === 4) {
      startDate = Moment().startOf('year').toDate();
      endDate = Moment().endOf('year').toDate();
    } else {
      startDate = Moment().subtract(6, 'months').toDate(); // 6 months before today
      endDate = new Date();
    }
    const year = new Date().getFullYear();
    const outSidePropertySlug = localStorage.getItem('outSidePorpertySlug') || '';
    const singlePropertySlug = localStorage.getItem('singlePorpertySlug') || '';
    const selectedPropertyName = localStorage.getItem('selectedPropertyName') || '';
    const singlePropertyName = localStorage.getItem('singlePropertyName') || 'All Properties';

    const filterValue = `{
      "start_date": "${startDate}",
      "end_date": "${endDate}",
      "year": "${year}",
      "property_slug": "${outSidePropertySlug !== '' ? outSidePropertySlug : singlePropertySlug}",
      "property_name": "${outSidePropertySlug !== '' ? selectedPropertyName : singlePropertyName}"
    }`;
    
    localStorage.setItem("globleTracker", filterValue);

    setTimeout(() => {
      this.setState({ isFirstLoad: false });
        this.getProperty();
        this.notificationModel();
        this.getDashboardData();
     }, 4000);
    //  this.interval = setInterval(() => {
    //     this.notificationModel();
    //      }, 1 * 15 * 1000);

  }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }
 /* Get Dashboard data */
  async getDashboardData() {
    this.setState({ isLoaded: false });
    let propertySlug =
      localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? localStorage.getItem("outSidePorpertySlug") : "";
    var queryString = "";
    if (propertySlug) {
     queryString = "property_slug=" + propertySlug;
    }
   
    var resData = [];
    let res = await DashboardService.getDashboardData(queryString);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : [];
      resData.inspection = resData.data && resData.data.inspection ? resData.data.inspection : 0;
      resData.audit = resData.data ? resData.data.audit : 0;
      resData.pendingmro = resData.data ? resData.data.pendingmro : 0;
      resData.ongoingmro = resData.data ? resData.data.ongoingmro : 0;
      resData.completemro = resData.data ? resData.data.completemro : 0;
      resData.overduesmro = resData.data ? resData.data.overduesmro : 0;
      resData.cancelledmro = resData.data ? resData.data.cancelledmro :0;
      resData.pendingaction = resData.data ? resData.data.pendingaction : 0;
      resData.overduesaction = resData.data ? resData.data.overduesaction : 0;
      resData.completedaction = resData.data ? resData.data.completedaction:0;
      resData.backlogmro = resData.data ? resData.data.backlogmro : 0;
      resData.inspMonth = resData.data && resData.data.inspMonth  ? resData.data.inspMonth.split(',', ) : [];
      resData.auditMonth = resData.data && resData.data.auditMonth ? resData.data.auditMonth.split(',', ) : [];
      resData.auditlist = resData.data ? resData.data.auditlist : [];
      resData.inspectionList = resData.data ? resData.data.inspectionList : [];
      resData.dailyLogList = resData.data ? resData.data.dailyLogList : [];
      resData.activeUsers = resData.data ? resData.data.activeUsers : "";
      resData.lastAudit = resData.data ? resData.data.lastAudit : [];
      resData.lastInspection = resData.data ? resData.data.lastInspection : [];
    }
    this.setState({
      inspection: resData.inspection,
      audit: resData.audit,
      auditlist: resData.auditlist,
      inspectionList: resData.inspectionList,
      dailyLogList: resData.dailyLogList,
      activeUsers: resData.activeUsers,
      lastAudit: resData.lastAudit,
      lastInspection: resData.lastInspection,
      inspectionData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Inspection",
            data: resData.inspMonth,
            backgroundColor: "#6610f2",
            borderWidth: 1,
            fill: true,
          },
          {
            label: "Audit",
            data: resData.auditMonth,
            backgroundColor: "#00cccc",
            borderWidth: 1,
            fill: true,
          },
        ],
      },
      mroPieData: {
        labels: ["Pending", "On-going", "Overdue", "Completed", "Backlog","Cancelled"],
        datasets: [
          {
            data: [resData.pendingmro, resData.ongoingmro, resData.overduesmro, resData.completemro, resData.backlogmro,resData.cancelledmro],

            backgroundColor: ["#97a3b9", "#007bff", "#f56b62", "#74cb80", "#ff7bd7","#c5a070"],
            options: {
              plugins: {
                datalabels: {
                  display: true,
                  backgroundColor: "#ccc",
                  borderRadius: 3,
                  font: {
                    color: "red",
                    weight: "bold",
                  },

                },
                legend: {
                  position: "right",
                },
                doughnutlabel: {
                  labels: [
                    {
                      text: "550",
                      font: {
                        size: 20,
                        weight: "bold",
                      },
                    },
                    {
                      text: "total",
                    },
                  ],
                },
              },
            },
          },
        ],
      },
      actionItemPieData: {
        labels: ["Pending", "Overdue","Completed"],
        datasets: [
          {
            data: [resData.pendingaction, resData.overduesaction,resData.completedaction],
            backgroundColor: ["#97a3b9 ", "#f56b62","#74cb80"],
            hoverBackgroundColor:['#7b8cb5'],
          },
        ],
      }
      
    });
    this.setState({ isLoaded: true });
  }
  /* GET NOTIFICATION DATA */
  async notificationModel() {
    let notislist = [];
    let totalNotification = '';
    let res = await NotificationService.getNotificationListModel();
    if (global.successStatus.includes(res.status)) {
      notislist = res.data.notifications ? res.data.notifications : [];
      totalNotification = res.data.total_notifications ? res.data.total_notifications : [];
      
    }
    this.setState({ notislist: notislist, totalNotification: totalNotification });
  }

  /* Get property list */
  async getProperty(queryString = "") {
    this.setState({ isLoaded: false });
    var propertyList = [];
    var propertyListTotal = 0;
    var selectedPropertyList = [{value: '', label: "All Properties"}];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
    propertyListTotal > 0
      ? propertyList.map(({ slug, property_name }) => ({
          value: slug,
          label: property_name,
        }))
      : [];
  if (
    localStorage.getItem("outSidePorpertySlug") !== undefined &&
    localStorage.getItem("outSidePorpertySlug") !== null &&
    localStorage.getItem("outSidePorpertySlug") !== ""
  ) {
    if (propertyListTotal > 0) {
      propertyList.map((opt, i) => {
        if (opt.slug === localStorage.getItem("outSidePorpertySlug")) {
          selectedPropertyList = [
            {
              value: opt.slug,
              label: opt.property_name,
            },
          ];
        }
      });
    }
  } 
    const customOptions = [
      {
        value: '',
        label: 'All Properties',
      },
      ...newPropertyList,
    ];
    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: customOptions,
      selectedPropertyList: selectedPropertyList,
      isLoaded: true,
    });
    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: customOptions,
      selectedPropertyList: selectedPropertyList,
    });
  }
  /* Chnage handle for property*/
  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    const property_slug_value = value !== null ? value.value : [];
    const property_slug_label = value !== null ? value.label : [];
    this.setState({
      selectedPropertyList: value,
      property_slug: property_slug_value,
    });
    localStorage.setItem("outSidePorpertySlug", property_slug_value);
    localStorage.setItem("selectedPropertyName", property_slug_label);
    localStorage.setItem("singlePorpertySlug", property_slug_value);
    localStorage.setItem("singlePropertyName", property_slug_label);
    /* For Tracker */
    let startDate, endDate;
    if(global.roleTypeId === 4) {
      startDate = Moment().startOf('year').toDate();
      endDate = Moment().endOf('year').toDate();
    } else {
      startDate = Moment().subtract(6, 'months').toDate(); // 6 months before today
      endDate = new Date();
    }
    const year = new Date().getFullYear();
      var filterValue =
      '{"start_date":"' +
      startDate +
        '", "end_date":"' +
        endDate +
        '", "year":"' +
        year +
      '", "property_slug" :"' +
      property_slug_value +
      '" , "property_name" :"' +
      property_slug_label +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    this.setState({ confirm_back: 1 });
    this.setState({ isLoaded: false });
    this.getDashboardData();
  }

  /* Click on Notification title */
  notificationRead(elm){
    let moduleName = elm.ref_table_id ? elm.ref_table_id :"";
    let moduleSlug = elm.ref_table_rec_slug ? elm.ref_table_rec_slug :"";
    if(moduleName != undefined){
      if(moduleName === 1){
        window.open(`/mros/update/${moduleSlug}`, "_blank" );
      }
      if(moduleName === 3){
        window.open(`/action-item/update/${moduleSlug}`, "_blank" );
      }
      
    }

  }
  render() {
    if (this.state.isFirstLoad) {
      // Show <Landing /> on first load
      return <Landing />;
    }
    return (
     
        <section className="dashboard-section-box">


          <div className="d-flex az-header-top justify-content-between">
            <div className="az-header-left"></div>
            <div className="az-header-center">
              <div className="az-content-body d-flex flex-column">
                <h1 className="text-center dash_heading font-weight-bold text-danger">
                WELCOME TO GFBOT
                </h1>

              </div>
            </div>

           
             <div className="az-header-right">
              <p className="m-0 header_help">
                <span className="tx-16 contact_us_dashbord"
                    onClick={() => window.location = 'mailto:gfbotsupport@gfhotels.com'}>
                    Contact us
                </span>
                 
                </p>
                <Dropdown className="az-header-notification">
                <Dropdown.Toggle as={"a"} className="new hand-cursor">
                  <i className="typcn typcn-bell"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                 <> <div className="az-dropdown-header mg-b-20 d-sm-none">
                    <a
                      href="#/"
                      onClick={event => this.closeMenu(event)}
                      className="az-header-arrow"
                    >
                      <i className="icon ion-md-arrow-back"></i>
                    </a>
                  </div></>
                        <>
                        <div className="nofi_header_top">
                    <h6 className="az-notification-title">Notifications</h6>
                  </div>
                  </>
                  
                  <>
                  
                        <p className="az-notification-text" >You have unread notification</p>
                        </>
                  <div className="az-notification-list">
                  {this.state.notislist!= undefined && this.state.notislist.length > 0 ?  this.state.notislist.map((elm, index) => (<>
                    <div className={elm.is_read == 1 ? "media" : "media noti_unread"} key={"key_"+index} >
                      <div className="media-body ml-0" onClick={() => this.notificationRead(elm)}>
                        <p>
                          {elm.message_subject ? elm.message_subject :""}
                        </p>
                        <span> {elm.created_at ? Moment(elm.created_at).format("MMM DD YY hh:mm A") : ""}</span>

                      </div>
                    </div>

                    </>)) : ""}
                  </div>
                

                  <div className="dropdown-footer">
                    <a href="/notification">View All Notifications</a>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>  
          
          </div>
          {localStorage.getItem('activePropertyCount') == 1 ? "" : (<>
          <div className="container-fluid dash_borad_sec">
            <div className="row">
              <div className="col-4 mg-t-20">
                  <div className="dashboard_prop_dropdown">
                  <label className="mg-b-5">Property</label>
                  {this.state.isLoaded ? (<Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedPropertyList}
                      options={this.state.newPropertyList}
                      isSearchable={true}
                      onChange={(value) =>
                        this.handleChangePropertyList(value)
                      }
                      defaultValue={this.state.selectedPropertyList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                            primary75: "#808080",
                            primary50: "#808080",
                            primary25: "#808080",
                        },
                      })}
                      />) : (
                        <Skeleton height={40} />
                      )}
                  </div>
              </div>
            </div>
          </div>

          </>)}


          {/* Property user */}
          {global.roleTypeId === 4 ? (<>
            <div className="container dash_borad_sec">
            <div className="row">
              {(global.userPermissions.checkPermission("daily_logs-list") ||
                global.userPermissions.checkPermission("inspection-list")) && (
                <div className="col-lg-4 col-md-6 abc">
                  <div className="row">
                    {global.userPermissions.checkPermission("daily_logs-list") && (
                      <div className="col-12 mg-t-20 xyz">
                        <RecentDailyLog
                          dailyLogList={this.state.dailyLogList}
                          isLoading={!this.state.isLoaded}
                        />
                      </div>
                    )}
                    {global.userPermissions.checkPermission("inspection-list") && (
                      <div className="col-12 mg-t-20 xyz">
                        <RecentInspections
                          inspectionList={this.state.inspectionList}
                          isLoading={!this.state.isLoaded}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-lg-8 col-md-6">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-xl-6 mg-t-15 mg-t-20">
                      <MroStatus  mroPieData ={this.state.mroPieData} doughnutPieOptions ={this.state.doughnutPieOptions} />
                      </div>

                      <div className="col-xl-6 mg-t-15 mg-t-20">
                      <ActionItemStatus actionItemPieData={this.state.actionItemPieData} doughnutPieOptionsForAction={this.state.doughnutPieOptionsForAction} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                      <div className="row">
                        <div className="property-insp-audit-box">
                          <InspectionNumber inspection ={this.state.inspection} isLoading={!this.state.isLoaded} />
                          <AuditsNumber audit ={this.state.audit} isLoading={!this.state.isLoaded} />
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mg-t-20">
              <MonAuditInspection inspectionData ={this.state.inspectionData} auditData ={this.state.auditData} />
              </div>
            </div>
          </div>
          
          
          </>) : ""}
          {/* Corporate Admin */}
          {/* {global.roleTypeId === 5 ? (<>
            <div className="container dash_borad_sec">
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <div className="row">
                  <div className="col-md-3">
                    <div className="row">
                    <AuditsNumber  audit ={this.state.audit} />
                      <div className="col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-12 mg-t-20">
                          <RecentAudits  auditlist ={this.state.auditlist} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-xl-12 mg-t-15 mg-t-20">
                      <ActionItemStatus actionItemPieData ={this.state.actionItemPieData} doughnutPieOptionsForAction ={this.state.doughnutPieOptionsForAction} />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 mg-t-20">
                  <MonAuditInspection inspectionData ={this.state.inspectionData} auditData ={this.state.auditData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>) : ""} */}
          {/* There user */}
          {global.roleTypeId === 1 || global.roleTypeId === 2 || global.roleTypeId === 3 || global.roleTypeId === 5 || global.roleTypeId === 6 ? (<>
            <div className="container-fluid dash_borad_sec">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                  {global.userPermissions.checkPermission("daily_logs-list") && (
                    <div className="col-lg-4 mg-t-20">
                      <RecentDailyLog dailyLogList={this.state.dailyLogList} isLoading={!this.state.isLoaded} />
                    </div>
                  )}
                  {global.userPermissions.checkPermission("audits-list") && (
                    <div className="col-lg-4 mg-t-20">
                      <RecentAudits  auditlist ={this.state.auditlist} isLoading={!this.state.isLoaded} />
                    </div>
                  )}
                  {global.userPermissions.checkPermission("inspection-list") && (
                    <div className="col-lg-4 mg-t-20">
                      <RecentInspections inspectionList={this.state.inspectionList} isLoading={!this.state.isLoaded} />
                      </div>
                  )}
                  </div>
                </div>
                <div className="col-lg-12 dash_middle_sec">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="row">
                        <InspectionNumber  inspection ={this.state.inspection} isLoading={!this.state.isLoaded} />
                        <AuditsNumber  audit ={this.state.audit} isLoading={!this.state.isLoaded} />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-xl-6 mg-t-15 mg-t-20">
                          <MroStatus  mroPieData ={this.state.mroPieData} doughnutPieOptions ={this.state.doughnutPieOptions} isLoading={!this.state.isLoaded} />
                      </div>
                      
                        <div className="col-xl-6 mg-t-15 mg-t-20">
                        <ActionItemStatus actionItemPieData={this.state.actionItemPieData} doughnutPieOptionsForAction={this.state.doughnutPieOptionsForAction} isLoading={!this.state.isLoaded} />
                      </div>
                       
                      </div>
                    </div>
                    <div className="col-12 mg-t-20 mg-b-20">
                    <MonAuditInspection inspectionData ={this.state.inspectionData} auditData ={this.state.auditData} isLoading={!this.state.isLoaded} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>) : ""}
          
          
           
          
        </section>
    );
  }
}
export default Dashboard;
